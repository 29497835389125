import { Analytics } from '@vercel/analytics/react';
import type { AppProps, AppType } from "next/app";
import Script from "next/script";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../styles/globals.css";
import { trpc } from "../utils/trpc";

const gaTrackingId = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

export { reportWebVitals } from 'next-axiom';

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Script src="/js/main.js"></Script>
      {process.env.NODE_ENV === "development" || !gaTrackingId ? null : (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
          />
          <Script
            async
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      <Component {...pageProps} />
      <Analytics />
    </>
  );
}

export default trpc.withTRPC(MyApp);
